<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="item.to"
    active-class="primary white--text"
    link
    class="py-1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon
      v-if="!item.icon"
      class="
        text-caption text-uppercase
        justify-center
        ml-1
        my-2
        align-self-center
      "
    >
      {{ title }}
    </v-list-item-icon>

    <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-icon v-if="item.icon" class="my-2 align-self-center">
      <v-icon v-text="item.icon" color="secondary" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <div style="display: flex; flex-flow: wrap">
        <div
          v-text="item.title"
          style="
            border-radius: 5px;
            background: #352703;
            color: #fad36e;
            padding: 1px 5px;
          "
        ></div>
        <!-- <v-list-item-title
          v-text="item.title"
          style="
            border-radius: 25px;
            text-align: center;
            background: #352703;
            color: #fad36e;
          "
        /> -->
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "DefaultListItem",

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    },
  },
};
</script>
